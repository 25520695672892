.serviciosContainer.comoTrabajamos{
    flex-direction: row;
    justify-content: space-between;
}

.comoTrabajamosImg{
    background-image: url(https://loyalty-optimized.s3.sa-east-1.amazonaws.com/trabajando.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 86%;
    height: 400px;
    margin: 0 7% 100px 7%;
    position: relative;
}

.logoInImg{
    height: auto;
    width: 100px;
    position: absolute;
    top: 15px;
    right: 15px;
}


/* Estilos para tabletas y laptops pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
    .comoTrabajamosImg{
        margin: 0 4% 70px 4%;
    }
    .comoTrabajamos{
        margin-bottom: 20px;
    }
}

/*Estilos para celulares*/
@media (max-width: 768px) {
    .comoTrabajamosImg{
        margin: 0;
        height: 300px;
        width: 100%;
    }

    .comoTrabajamosImg img{
        width: 60px;
    }

    .comoTrabajamos{
        flex-direction: column !important;
    }

    .comoTrabajamos .servicioContainer{
        margin-bottom: 30px;
    }

}