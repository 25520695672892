@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.container {
  min-height: calc(100vh - 60px);
  width: 100%;
  background-color: rgb(246, 246, 246);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.heroContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 30px);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroOverlay {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.251);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.heroTextContainer {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 3% 7%;
}

.heroTextContainer h1 {
  color: rgb(255, 255, 255);
  font-size: 80px;
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  align-self: flex-start;
  margin: 20px 0;
}

.heroTextContainer p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: rgb(255, 255, 255);
}

.heroTextContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #060606bc;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  width: 150px;
  margin: 30px 0;
  border: solid 1px #fff;
  cursor: pointer;
}

.heroTextContainer button:hover {
  background-color: #282525ad;
}

.simbolo {
  position: absolute;
  top: 30px;
  right: 7%;
  height: 120px;
  z-index: 1;
}

.upperContainer {
  padding: 50px 7%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  margin-bottom: 30px;
}

.upperContainer h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-size: 60px;
  margin-bottom: 20px;
  font-style: normal;
  color: #1f2d55;
}

.upperContainer h3 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 15px;
}

.upperContainer h1 {
  color: #1f2d55;
  font-size: 35px;
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  /* font-family: "Plus Jakarta Sans", sans-serif;
    font-optical-sizing: auto; */
  font-weight: 400;
  font-style: normal;
}

.upperContainer img {
  height: 20px;
  margin-right: 20px;
}

.container.regular p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  /* padding: 0 7%; */
}


/* Estilos para tabletas y laptops pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
  .heroTextContainer {
    padding: 3% 4%;
  }

  .heroTextContainer h1 {
    font-size: 65px;
  }

  .upperContainer {
    padding: 30px 4%;
  }
}

/*Estilos para celulares*/
@media (max-width: 768px) {
  .heroTextContainer {
    padding: 25% 10px;
  }

  .heroTextContainer h1 {
    font-size: 50px;
    opacity: 0;
  }

  .heroTextContainer p {
    font-size: 16px;
  }

  .heroTextContainer button{
    width: 100%;
    padding: 7px 10px;
  }

  .upperContainer {
    padding: 30px 10px;
  }

  .upperContainer h2{
    font-size: 35px;
  }

  .upperContainer h3{
    font-size: 16px;
  }
}