.quienesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 7%;
    margin-bottom: 50px;
}

.quienesTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 30px 7%;
}

.quienesTextContainer .text {
    /* font-size: 20px !important; */
    margin-bottom: 30px;
}

.quienesStaffContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 80px;
}

.staffContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0px;
    background-image: url(https://loyalty-optimized.s3.sa-east-1.amazonaws.com/bgBlue.jpg);
    background-size: 50px;
    background-repeat: repeat;
}

.tarjetaContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0px 0;
}

.tarjetaContainer p {
    align-self: flex-end !important;
    /* text-decoration: underline; */
    font-style: oblique !important;
    color: #fff !important;
    margin: 0 20px 10px 20px;
}

.tarjetaImg {
    width: 100%;
    height: 400px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

.tarjetaImg.gerente {
    background-image: url(https://loyalty-optimized.s3.sa-east-1.amazonaws.com/mihura.jpg);
}

.tarjetaImg.presidente {
    background-image: url(https://loyalty-optimized.s3.sa-east-1.amazonaws.com/marcos.jpg);
}

.quienesStaffContainer h2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 70px;
    margin-bottom: 20px;
    font-style: normal;
    color: #1f2d55;
}

.quienesStaffContainer h3 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    margin-bottom: 15px;
}

.quienesContainer svg {
    height: 70px;
    color: #63bbea;
}

.quienesContainer p svg {
    height: 10px;
    margin-right: 10px;
}

.quienesContainer .icons {
    margin: 30px 0;
    justify-content: space-around;
}

.quienesContainer p {
    align-self: flex-start;
}

.icons {
    width: 100%;
}

.quote {
    margin: 50px 0;
    font-style: oblique !important;
    font-family: "Lora", serif !important;
    font-optical-sizing: auto !important;
    font-weight: 600 !important;
    color: #1f2d55 !important;
    font-size: 22px !important;
}

/* Estilos para tabletas y laptops pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
    .quienesContainer {
        padding: 0 4%;
    }

    .staffContainer {
        width: 315px;
    }

    .quienesTextContainer {
        padding: 0px 0 30px 4%;
    }

    .quienesTextContainer .icons {
        margin: 0;
    }

    .quienesTextContainer .icons svg {
        height: 60px;
    }

    .quote {
        font-size: 20px !important;
    }

}

/*Estilos para celulares*/
@media (max-width: 768px) {
    .quienesContainer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 10px;
        margin-bottom: 50px;
    }

    .quienesTextContainer{
        padding: 0;
        padding-bottom: 30px;
    }

    .quienesTextContainer .icons{
        padding: 0;
    }

    .quienesTextContainer .icons svg {
        height: 50px;
    }

    .quienesTextContainer .quote{
        font-size: 18px !important;
    }

    .quienesStaffContainer{
        margin-bottom: 50px;
    }


}