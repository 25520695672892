.contactInfo{
    padding: 0px 7%;
}

.infoItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    font-style: oblique;
    color: rgb(62, 62, 62);
}

.infoItem svg{
    margin-right: 10px;
    color: #63bbea;
}

.formContainer{
    padding: 50px 7%;
    width: 100%;
    margin: 50px 0;
    background-image: url(https://loyalty-optimized.s3.sa-east-1.amazonaws.com/bgBlue.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.formContainer h3{
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}


.formContainer button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    background-color: #0606069c;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin: 30px 0;
    border: solid 1px #fff;
    cursor: pointer;
}

.formContainer button svg{
    height: 25px;
    margin-left: 15px;
}

.formContainer button:hover {
    background-color: #282525ad;
}

.formGroup{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.formGroup label{
    color: #ffffff;
}

.formContainer input{
    width: 300px;
    padding: 10px 15px;
}

.formContainer textarea{
    width: 600px;
    padding: 10px 15px;

}

.mapContainer h3{
    color: #1f2d55;
    font-size: 22px;
    font-weight: 600;
    padding: 20px 7%;
}

/* Estilos para tabletas y laptops pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
    .contactInfo{
        padding: 0 4%;
    }

    .formContainer{
        padding:  50px 4%;
    }

    .mapContainer h3{
        padding: 20px 4%;
    }
}

/*Estilos para celulares*/
@media (max-width: 768px) {
    .contactInfo{
        padding: 0 10px;
    }

    .contactInfo .infoItem p{
        font-size: 14px;
    }

    .formContainer{
        padding: 30px 10px;
    }

    .formContainer h3{
        font-size: 18px;
    }

    .formContainer input{
        padding: 7px 10px;
        width: 100%;
    }

    .formContainer textarea{
        width: 100%;
    }

    .formContainer button{
        width: 100%;
        padding: 7px 10px;
    }

    .mapContainer h3{
        font-size: 18px;
    }
}