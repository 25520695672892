.footerContainer {
    background-color: #171620;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 100px 7% 0 7%;
    margin: 0 auto;
}

.footerTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.footerImg,
.footerLinks,
.footerSocial {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footerImg {
    align-items: flex-start;
}

.footerImg img {
    height: 40px;
    margin-top: 10px;
}

.footerLinks h4,
.footerSocial h4 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #787878;
}

.footerLinks {
    align-items: center;
}

.footerLinks ul {
    list-style-type: none;
    padding: 0;
}

.footerLinks ul li {
    margin-bottom: 10px;
}

.footerLinks ul li a {
    color: white;
    text-decoration: none;
}

.footerLinks ul li a:hover {
    color: #63bbea;
}

.footerSocial {
    align-items: flex-end;
}

.footerSocial .socialIcons a {
    color: white;
    margin-left: 10px;
    font-size: 30px;
    display: inline-block;
}

.footerSocial .socialIcons a:hover {
    color: #63bbea;
}

.footerCopy {
    text-align: center;
    padding: 100px 0 40px 0;
    font-size: 14px;
    color: #434343;
}

/* Estilos para tabletas y laptops pequeños */
@media (min-width: 769px) and (max-width: 1024px) {
    .footerContainer{
        padding: 30px 4% 0 4%;
    }
}

/*Estilos para celulares*/
@media (max-width: 768px) {
    .footerContainer {
        padding: 10px 10px 0 10px;
    }

    .footerTopContainer {
        flex-direction: column;
        align-items: center;
    }

    .footerImg,
    .footerLinks,
    .footerSocial {
        width: calc(100%);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 0;
        border-bottom: solid 1px #434343;
    }

    .footerLinks ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .footerLinks ul li {
        width: auto;
    }

    .footerImg img{
        height: 25px;
        align-self: start;
    }

    .footerCopy {
        padding: 50px 0 30px 0;
    }
}